import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Snackbar } from '@andes/snackbar';
import ModalAddToCart from '@mshops-components-library/modal-add-to-cart';

import { useRenderContext } from '../pages/home/context';
import { useCartContext, useCartDispatcher } from '../pages/marketplaceHome/context/cart';
import { useFreeShippingDispatcher } from '../pages/marketplaceHome/context/freeShipping';
import { dispatchAddToCardShopsInfo, getFormattedTrackingData, getTracks, sendAddTrack, sendRemoveTrack } from '../utils/addToCartTracks';

const updateCartFreya = (isWebview) => {
  if (isWebview && window?.MobileWebKit) {
    window.MobileWebKit.executeNative({
      method: 'refresh_cart',
      args: {},
    });
    return;
  }

  if (window?.freya) {
    window.freya.emit('cart:refresh');
  }
};

function withAddToCart(WrappedComponent) {
  const AddToCart = (props) => {
    const SHOPS_TYPE = 'mshops';
    const cartActions = useCartDispatcher();
    const freeShippingActions = useFreeShippingDispatcher();
    const { addedItems, marketplaceInfo: { isWebview, userZipCode, shopType: marketplaceShopType } } = useCartContext();
    const shopType = marketplaceShopType || SHOPS_TYPE;
    const { osName, device, appearance } = useRenderContext();
    const [showError, setShowError] = useState(false);
    const [UrlAddToCartModal, setUrlAddToCartModal] = useState(null);
    const { item, cartErrorSnackbarLabel: errorSnack, id: shopsId, modalTitle, i18n } = props;
    const itemId = item ? item.id : shopsId;
    const cartErrorSnackbarLabel = (errorSnack && errorSnack?.length > 0) ? errorSnack : i18n.gettext('¡Ups! hubo un error');

    const foundItem = addedItems?.find(cartItem => cartItem.id === itemId);

    const updateCartUI = () => {
      if (shopType === SHOPS_TYPE) {
        return cartActions.getCartItems(shopType);
      }

      freeShippingActions.updateFreeShippingBar(userZipCode, shopType);
      return updateCartFreya(isWebview);
    };

    const deleteItem = async (id, quantity, variation) => {
      try {
        await cartActions.deleteCartItem(
          id, variation, userZipCode, shopType,
        );
        const tracks = getTracks(appearance, itemId);

        sendRemoveTrack(tracks, { quantity });
        updateCartUI();
        return true;
      } catch (error) {
        if (error?.response?.status === 401) {
          window.location.assign(error.response.data.login);
          return false;
        }
        // TODO: we do this to avoid show snackbar while prop is empty, delete once backend begins to send this
        setShowError(true);
        return false;
      }
    };

    const updateItem = async (id, quantity, variation) => {
      try {
        await cartActions.updateCartItem(
          id, quantity, variation, userZipCode, shopType,
        );
        const tracks = getTracks(appearance, itemId);

        if (quantity > foundItem?.quantity) {
          sendAddTrack(tracks, { quantity });
        } else if (quantity < foundItem?.quantity) {
          sendRemoveTrack(tracks, { quantity });
        }

        updateCartUI();
        return true;
      } catch (error) {
        if (error?.response?.status === 401) {
          window.location.assign(error.response.data.login);
          return false;
        }
        // TODO: we do this to avoid show snackbar while prop is empty, delete once backend begins to send this
        setShowError(true);
        return false;
      }
    };

    const addItem = async (id, quantity, variation) => {
      try {
        const { data } = await cartActions.addToCart(
          id, quantity, variation, userZipCode, shopType,
        );

        if (data?.url) {
          setUrlAddToCartModal(data.url);
        }

        const tracks = getTracks(appearance, itemId);

        if (window?.tracking_mediator && shopType === SHOPS_TYPE) {
          const shopsTrackingData = getFormattedTrackingData(
            {
              currency: item?.price?.currency_id,
              price: item?.price?.value,
              title: item?.title,
              item_id: id,
              brand: item?.brand_info?.name || '',
              name: item?.title,
              quantity,
            },
            variation,
          );

          dispatchAddToCardShopsInfo(window.tracking_mediator, shopsTrackingData);
        }

        sendAddTrack(tracks, { quantity });
        updateCartUI();
        return true;
      } catch (err) {
        if (err?.response?.status === 401) {
          window.location.assign(err.response.data.login);
          return false;
        }
        // TODO: we do this to avoid show snackbar while prop is empty, delete once backend begins to send this
        setShowError(true);
        return false;
      }
    };

    const syncSnackbar = (show) => {
      setShowError(show);
    };

    return (
      <>
        <ModalAddToCart
          id=""
          title={modalTitle}
          src={UrlAddToCartModal}
          onCloseModal={() => setUrlAddToCartModal(null)}
          deviceType={device}
          show={!!UrlAddToCartModal}
        />
        <WrappedComponent
          {...props}
          eshopType={shopType}
          addItem={addItem}
          updateItem={updateItem}
          deleteItem={deleteItem}
          cartItemQuantity={foundItem ? foundItem.quantity : 0}
          osName={osName}
          deviceType={device}
          userZipCode={userZipCode}
        />
        <Snackbar
          show={showError}
          color="red"
          delay={5000}
          onClose={syncSnackbar}
          message={cartErrorSnackbarLabel}
        />
      </>
    );
  };

  AddToCart.propTypes = {
    item: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
    id: PropTypes.string,
    cartErrorSnackbarLabel: PropTypes.string,
  };

  AddToCart.defaultProps = {
    id: null,
    cartErrorSnackbarLabel: '',
  };

  return AddToCart;
}

export default withAddToCart;
