/**
 * Module dependencies
 */
const React = require('react');
const { hydrateRoot } = require('nordic/hydrate');
const { loadableReady } = require('nordic/lazy');
const I18n = require('nordic/i18n');
const I18nProvider = require('nordic/i18n/I18nProvider');
const { AndesProvider } = require('@andes/context');
const newRelicErrorHandler = require('../utils/services/newRelic/errorHandler');

function startApp(Component) {
  /**
   * Get server state
   */
  const {
    site,
    siteId,
    lowEnd,
    deviceType,
    company,
    translations,
    shopModel,
    config,
    editable,
    section,
    siteKey,
    apiBasePath,
    hideModalSubscription,
    isHubPreview,
    isRobotsScope,
    manifest,
    assetsPrefix,
    uuidVersion,
    locale,
    device,
    features,
    csrfToken,
    preloadImages,
    experiments,
  } = window.__PRELOADED_STATE__; // eslint-disable-line no-underscore-dangle

  /**
   * i18n
   */
  const i18n = new I18n({ translations });

  if (window && window.newrelic) {
    window.newrelic.setErrorHandler(newRelicErrorHandler);
  }

  /**
   * Mount HomeView on client
   */
  loadableReady(() => {
    hydrateRoot(
      document.getElementById('root-app'),
      <AndesProvider locale={locale} device={device} csrfToken={csrfToken}>
        <I18nProvider i18n={i18n}>
          <Component
            site={site}
            siteId={siteId}
            lowEnd={lowEnd}
            deviceType={deviceType}
            company={company}
            shopModel={shopModel}
            config={config}
            editable={editable}
            section={section}
            siteKey={siteKey}
            apiBasePath={apiBasePath}
            hideModalSubscription={hideModalSubscription}
            isHubPreview={isHubPreview}
            isRobotsScope={isRobotsScope}
            manifest={manifest}
            assetsPrefix={assetsPrefix}
            uuidVersion={uuidVersion}
            locale={locale}
            device={device}
            features={features}
            csrfToken={csrfToken}
            preloadImages={preloadImages}
            experiments={experiments}
          />
        </I18nProvider>
      </AndesProvider>,
    );
  });
}

module.exports = startApp;
